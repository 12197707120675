<template>
  <div class="container">
	<div style="margin: 20px 0;">
		<box title="投诉举报">
		  <div style="width: 100%;">
		    <van-form validate-first @submit="bindSubmit" label-align="right" ref="form">
		      <van-field name="name" label="申诉人姓名" placeholder="请填写申诉人姓名"
		        v-model="form.name" :rules="rules.name" :required="true"
		      />
		
			  <van-field name="身份证号" label="身份证号" placeholder="请填写身份证号"
			    v-model="form.idCard" :rules="[{ validator: checkIdcard, message: '请填写有效证件号码' }]"
			  />
					
			  <van-field name="phone" type="tel" label="联系电话" placeholder="请填写联系电话"
			    v-model="form.phone" :rules="[{ validator: checkPhone, message: '请填写有效证件号码' }]"
			  />
		
			  <van-field name="departComplainedAgainst" label="被投诉部门" placeholder="请填写被投诉部门"
			    v-model="form.departComplainedAgainst"
			  />
			  
		      <van-field v-model="form.content" type="textarea"
		        name="content" rows="8" label="投诉内容" placeholder="请填写投诉内容"
		      />
		
			  <van-field name="uploader" label="拍照上传">
			    <template #input>
				  <van-uploader max-count="9" v-model="annexList" :after-read="afterReadAnnex"/>
			    </template>
			  </van-field>
		
		      <div style="margin: 16px;display: flex;">
		        <van-button round block type="danger" native-type="submit"> {{ $t('public.submit') }} </van-button>
				<!-- <van-button round block type="danger" native-type="button" @click="goBack"> {{ $t('public.goBack') }} </van-button> -->
		      </div>
		    </van-form>
		  </div>
		</box>  
	</div>
	<!-- 底部内容 -->
	<Footer />
  </div>
</template>

<script>
import BASE from "@/utils/base";
import Box from '../../supervise/components/Box';
import { IdCardValid } from "@/utils/IdCardValid";
import Footer from "@/components/Footer";
// ali oss
let OSS = require('ali-oss');

export default {
  name: 'PatrolReport',
  components: { Box, Footer },
  data() {
    return {
      form: {
        
      },
	  // 附件列表
      annexList: [],
	  // 上传状态
	  uploadStatus: {
	    uploading: this.$t('uploadStatus.uploading'),
	    failed: this.$t('uploadStatus.failed'),
	    done: this.$t('uploadStatus.done')
	  },
    }
  },
  watch: {
    annexList: {
      handler(newV) {
        const arr = []
        newV.forEach(e => {
          if (e.url !== undefined && e.url !== '') {
            arr.push(e.url)
          }
        });
        this.form.img = arr.join(',')
      },
      deep: true
    }
  },
  computed: {
    rules() {
      
      return {
        name: [{ required: true, message: '请填写企业名称' }],
      }
    }
  },
  activated() {
    this.form = {
      name: '',       // 投诉人姓名
      idCard: '',//身份证号
      phone: '', // 联系电话
	  departComplainedAgainst: '',//被投诉部门
      content: '', // 投诉内容
	  img: ''
    }
	this.annexList = []
  },
  mounted() {
  },
  methods: {
	// 验证身份证号
	checkIdcard(value) {
		if(value) {
			if (value.length === 18) return IdCardValid(value)
			return false
		}
	},
	// 验证手机号
	checkPhone(value) {
		if(value) {
			if(/^1[3456789]\d{9}$/.test(value)) {
				return true;
			}else{
				return false;
			}
		}
	},
	/**
	 * 附件上传
	 * 需要改成oss上传
	 */
	 afterReadAnnex(file) {
	  file.status = 'uploading';
	  file.message = this.uploadStatus.uploading;
	  // 初始化上传状态 - 失败
	  let status = 'failed'
	  // 初始化
	  file.url = '';
	
	  this.axios
	    .post(BASE.genUrl("zxUpload/uploadOSS", {}))
	    .then(async res => {
	      // 获取数据
	      const { accessKeyId, accessKeySecret, securityToken: stsToken, dir } = res.data.result
	      // 拼接文件名
	      const fileName = dir + (new Date()).valueOf() + '_' + file.file.name;
	      // 发送oss
	      const r = await new OSS({
	        region: process.env.VUE_APP_OSS_REGION,
	        accessKeyId,
	        accessKeySecret,
	        stsToken,
	        bucket: process.env.VUE_APP_OSS_BUCKET,
	      }).put(fileName, file.file)
	
	      console.log(r)
	
	      const { statusCode, requestUrls } = r.res
	
	      // 判断是否上传成功
	      if (statusCode === 200) {
	        status = 'done';
	        // file.url = requestUrls[0]
	        file.url = 'https://videowll.xjiot.link/' + fileName
	      }
	      // 修改上传状态
	      file.status = status
	      file.message = this.uploadStatus[status];
	
	    })
	},
    bindSubmit(e) {
      const that = this

      this.axios
        .post(BASE.genUrl("zxSuperviseLetter1/add", {}), this.form)
        .then(async res => {
          const { code = 201, message = '' } = res.data
          if (code == 200) {
            that.$Toast({
              message: '操作成功',
              onClose: () => that.$router.go(-1)
            });
          } else {
            that.$Toast(message);
          }
        })

    },
	goBack() {
		this.$router.go(-1);
	}
  }
}
</script>

<style lang="less" scope>
  .content {
    padding: 16px;
    text-align: left;
  }
  .notice-title {
    font-size: 18px;
    text-align: center;
    padding: 10px 0;
  }
  .notice-content {
    font-size: 14px;
    line-height: 1.8;

    &__title, &__text > p {
      margin-bottom: 10px;
    }

    &__title {
      font-weight: 600;
    }
  }

  .agree{
    font-weight: 600;
    margin-bottom: 16px;
  }
</style>
